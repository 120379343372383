import React, {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  ImmerReducer,
  createActionCreators,
  createReducerFunction,
} from 'immer-reducer';
import createPersistedReducer from 'use-persisted-reducer';
import { LoginResponse, NotifyUpdateOrder, User } from '@meniu/data';
import { useProfile, useRefreshToken } from 'apiClient/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { modalOptions } from './menu.context';
import { useQueryClient } from 'react-query';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  UserCredential,
  getAuth,
  signInWithRedirect,
} from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA6RXs_sciBIVMym3-JWRehFhyloaHunmo',
  authDomain: 'meniuapp.firebaseapp.com',
  projectId: 'meniuapp',
  storageBucket: 'meniuapp.appspot.com',
  messagingSenderId: '157864816591',
  appId: '1:157864816591:web:cfec6ddfbf8a965953acba',
  measurementId: 'G-5CR9TPHV1H',
};

type OrderType = 'order' | 'tableorder';

const app = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

const usePersistedReducer = createPersistedReducer('auth');

const initialState: {
  auth: LoginResponse;
  user: User;
  refetchUser?: () => void;
  isLogged: boolean;
  demo?: string;
  modal: Array<modalOptions>;
  message: string;
  hasSeen?: Array<string>;
  enableMeniuNotifications: boolean;
  tempModalData: any;
  orderId: string | undefined;
  tableOrderId: string | undefined;
  signInWithGoogle: () => Promise<UserCredential>;
} = {
  auth: {
    access_token: '',
    success: false,
  },
  isLogged: false,
  demo: '',
  user: { _id: '', maxMenus: 1 },
  modal: [],
  message: '',
  hasSeen: [],
  orderId: '',

  tableOrderId: '',
  enableMeniuNotifications: false,
  tempModalData: {},
  signInWithGoogle: () => ({} as any),
};

class GlobalReducer extends ImmerReducer<typeof initialState> {
  setToken(response: LoginResponse, demo?: string, cb?: any) {
    this.draftState.auth = response;
    this.draftState.isLogged = true;
    if (demo) {
      this.draftState.demo = demo;
    } else {
      this.draftState.demo = '';
    }
    this.closeModal();
    cb?.();
  }
  logout() {
    const message = {
      type: 'logOut',
      data: 'googleSignIn',
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));

    this.resetUser();
  }
  setEditOrderId(orderId: string | undefined) {
    this.draftState.orderId = orderId;
  }

  setTempModalData(tempModalData: any) {
    this.draftState.tempModalData = tempModalData;
  }
  hasSeenNew(feature: string) {
    if (this.draftState.hasSeen?.includes(feature)) return;
    this.draftState.hasSeen = [...(this.draftState.hasSeen || []), feature];
  }
  setEnableNotification() {
    this.draftState.enableMeniuNotifications = true;
  }
  setUser(user: User) {
    this.draftState.user = user;
    this.draftState.isLogged = true;
  }
  setDemo(demo: string) {
    this.draftState.demo = demo;
  }
  resetUser() {
    this.draftState = { ...initialState };
  }
  setModal(name: Array<modalOptions> | modalOptions | any, message?: string) {
    this.draftState.modal = Array.isArray(name) ? name : [name];
    this.draftState.message = message || '';
  }
  closeModal(modal?: modalOptions) {
    if (modal) {
      const index = this.draftState.modal.findIndex((m) => m === modal);
      if (index > -1) {
        this.draftState.modal.splice(index, 1);
      }
    } else {
      this.draftState.modal = [];
    }
    this.draftState.message = '';
    this.draftState.orderId = undefined;
    this.draftState.tableOrderId = undefined;
  }
}

export const AppStateContext = createContext<typeof initialState>(initialState);

export const GlobalReducerFunction = createReducerFunction(GlobalReducer);

export const UserActions = createActionCreators(GlobalReducer);

const AppDispatchContext = createContext({});

function AppProvider(props: { children: ReactNode }) {
  const { children } = props;

  const query = useQueryClient();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const location = useLocation();

  const [state, dispatch] = usePersistedReducer(
    GlobalReducerFunction,
    initialState
  );

  const { mutate: refreshToken } = useRefreshToken();

  useEffect(() => {
    if (
      (location.pathname === '/login' || location.pathname === '/register') &&
      state.auth.access_token &&
      !state.demo
    ) {
      navigate('/', { replace: true });
    }

    if (state.auth.access_token) {
      query.invalidateQueries(['profile-me']);
      if (state.demo) return;
      refreshToken(
        {},
        {
          onSuccess: (response) => {
            if (response.access_token) {
              dispatch(UserActions.setToken(response));
            }
          },
        }
      );
    }
  }, [state.auth.access_token]);

  useEffect(() => {
    const redirect = `${Cookies.get('redirect')}`;
    if (redirect && redirect !== 'undefined') {
      Cookies.remove('redirect', { domain: '.meniuapp.com' });
      Cookies.remove('redirect');
      navigate(redirect);
    }
  }, []);

  const toastNotification = (newData: { menu: string; order: string }) => {
    toast.info(
      <div className="flex flex-column align-items-center justify-content-center">
        <audio controls autoPlay loop className="d-none">
          <source src="assets/sound.mp3" type="audio/ogg" />
        </audio>
        <div className="">
          <small className="fw-semibold text-dark">{t('Orden nueva')}</small>
        </div>
        <div className="my-2">
          <small className="text-muted">{t('¡Tienes una orden nueva!')}</small>
        </div>
        <div>
          <button
            className="btn border w-100 btn-sm mt-1 fw-semibold shadow-sm"
            onClick={() =>
              navigate(
                `/menu-editor/${newData.menu}?tab=orders&orderId=${newData.order}`
              )
            }
          >
            <small>{t('Ver orden')}</small>
          </button>
        </div>
      </div>,
      {
        autoClose: false,
        position: 'bottom-center',
      }
    );
  };

  const [binded, setBinded] = useState(false);

  useEffect(() => {
    if (binded) return;
    if (state.user.roles?.includes('user_pro')) {
      setBinded(true);
      const pusher = new Pusher(process.env.NX_PUSHER_KEY || '', {
        cluster: process.env.NX_PUSHER_CLUSTER || '',
      });
      const channel = pusher.subscribe('orders-channel');
      console.log('binded');
      channel.bind('order.created', (data: NotifyUpdateOrder | undefined) => {
        if (
          data?.userId === state.user._id ||
          data?.managedUsers?.includes(state.user._id || '')
        ) {
          query.invalidateQueries(['menu-orders']);
          if (data?.menuId && data?.orderId) {
            toastNotification({ menu: data?.menuId, order: data?.orderId });
          }
        }
      });
    }
  }, [state.user]);

  const { refetch: refetchUser } = useProfile(state.auth?.access_token, {
    onSuccess: (userResponse) => {
      dispatch(UserActions.setUser(userResponse));
    },
    enabled: state.isLogged,
  });

  return (
    <AppStateContext.Provider
      value={{
        ...state,
        refetchUser,
        signInWithGoogle: () =>
          signInWithRedirect(getAuth(app), googleProvider),
      }}
    >
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = useContext<typeof initialState>(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context as React.Dispatch<unknown>;
}

function useApp() {
  return [useAppState(), useAppDispatch()] as [
    typeof initialState,
    React.Dispatch<unknown>
  ];
}

export { AppProvider, useApp, useAppState, useAppDispatch };
