import { format } from 'date-fns';
import { es, enUS as en, pt } from 'date-fns/locale';
import { calculateBusinessHoursStatus } from './businessHoursUtils';

export const localeSelector = (locale = '') => {
  switch (locale) {
    case 'es':
      return es;
    case 'en':
      return en;
    case 'pt':
      return pt;
    default:
      return es;
  }
};

export const BusinessHours = ({
  schedule,
  onlyOpen,
  timezone,
  labels = {
    nonAvailable: 'Horario no disponible',
    closed: 'Cerrado',
    from: 'desde las',
    open: 'Abierto',
  },
  locale,
}: {
  schedule: any;
  timezone?: string;
  locale?: string;
  onlyOpen?: boolean;
  labels?: {
    openingHours?: string;
    nonAvailable: string;
    closed: string;
    open: string;
    from: string;
  };
}) => {
  if (!schedule || Object.keys(schedule).length === 0) {
    return null;
  }

  const businessStatus = calculateBusinessHoursStatus(schedule, timezone);

  if (!businessStatus) {
    return <p>{labels.nonAvailable}</p>;
  }

  const { isOpen, openingTime, closingTime } = businessStatus;

  if (onlyOpen)
    return (
      <small className="text-muted d-flex gap-2">
        {isOpen ? labels.open : labels.closed}
      </small>
    );

  if (!openingTime || !closingTime) {
    return (
      <div className="d-flex gap-1">
        <p className="fw-bold text-capitalize">
          {format(new Date(), 'iiii', { locale: localeSelector(locale) })}
        </p>
        <p>{labels.nonAvailable}</p>
      </div>
    );
  }

  return (
    <>
      {!isOpen && (
        <small className="fw-bold">
          {labels.closed},{' '}
          {labels.openingHours && (
            <>
              horario de atención {labels.from}
              <span className="fw-bold mx-1"> {openingTime} </span>a
              <span className="fw-bold mx-1">{closingTime}</span>
            </>
          )}
        </small>
      )}
      {isOpen && (
        <small className="d-flex align-items-center">
          <span className="text-md-start text-capitalize d-inline-block d-md-inline me-1">
            {labels.open}
          </span>
          {labels.from}
          <span className="fw-bold mx-1"> {openingTime} </span>a
          <span className="fw-bold mx-1">{closingTime}</span>
        </small>
      )}
    </>
  );
};

export default BusinessHours;
