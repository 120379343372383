import { useTranslation } from 'react-i18next';

export const Period = ({
  interval,
  interval_count,
}: {
  interval: any;
  interval_count: any;
}) => {
  const { t } = useTranslation();

  if (interval === 'month' && interval_count === 1) {
    return <>{t('month')}</>;
  }
  if (interval === 'month' && interval_count === 6) {
    return <>{t('6 meses')}</>;
  }
  if (interval === 'year' && interval_count === 1) {
    return <>{t('year')}</>;
  }
  return null;
};
