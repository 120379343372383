import { parse, format } from 'date-fns';

export type BusinessHoursStatus = {
  isOpen: boolean;
  openingTime: string | null;
  closingTime: string | null;
};

function getCurrentTime(timezone?: string): Date {
  if (timezone) {
    const match = timezone.match(/UTC([+-]\d{2}):(\d{2})/);
    if (match) {
      const offsetHours = parseInt(match[1]);
      const offsetMinutes = parseInt(match[2]);
      const now = new Date();
      const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const totalOffsetMinutes =
        offsetHours * 60 + (offsetHours < 0 ? -offsetMinutes : offsetMinutes);
      return new Date(utc.getTime() + totalOffsetMinutes * 60000);
    }
  }
  return new Date();
}

function parseTimeString(timeStr: string, referenceDate: Date): Date | null {
  if (!timeStr) return null;
  // Si no contiene un espacio (ej. "07:00PM"), se inserta antes de los dos últimos caracteres.
  if (!timeStr.includes(' ')) {
    timeStr =
      timeStr.slice(0, timeStr.length - 2) +
      ' ' +
      timeStr.slice(timeStr.length - 2);
  }
  const parsed = parse(timeStr, 'h:mm a', referenceDate);
  return parsed;
}

export function calculateBusinessHoursStatus(
  schedule: any,
  timezone?: string
): BusinessHoursStatus | null {
  if (!schedule || Object.keys(schedule).length === 0) return null;

  const currentTime = getCurrentTime(timezone);
  const currentDayName = format(currentTime, 'iiii').toLowerCase();
  const daySchedule = schedule[currentDayName];

  if (!daySchedule) return null;

  // Si el día indica que no está abierto, se retorna cerrado sin importar los horarios.
  if (!daySchedule.isOpen) {
    return { isOpen: false, openingTime: null, closingTime: null };
  }

  const { openingTime, closingTime } = daySchedule;
  if (!openingTime || !closingTime) {
    return { isOpen: false, openingTime: null, closingTime: null };
  }

  const openingDate = parseTimeString(openingTime, currentTime);
  const closingDate = parseTimeString(closingTime, currentTime);

  if (!openingDate || !closingDate) {
    return { isOpen: false, openingTime, closingTime };
  }

  let isCurrentlyOpen = false;
  // Si la hora de apertura y cierre son iguales se asume que está abierto todo el día.
  if (openingDate.getTime() === closingDate.getTime()) {
    isCurrentlyOpen = true;
  } else {
    isCurrentlyOpen = currentTime >= openingDate && currentTime <= closingDate;
  }

  return {
    isOpen: isCurrentlyOpen,
    openingTime,
    closingTime,
  };
}
