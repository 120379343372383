export const trialDays = 7;
export const monthPrice = 99;
export const semesterPrice = 499;
export const anualPrice = 699;
export const semesterDiscount = 16;
export const anualDiscount = 41;
export const semesterDiscountAmount = 95;
export const anualDiscountAmount = 489;
export const totalRestaurants = 4000;
export const defaultCountry = 'MX';
