import { ReactComponent as GridIcon } from 'assets/grid-outline.svg';
import { ReactComponent as PeopleIcon } from 'assets/people-outline.svg';
import { ReactComponent as CashIcon } from 'assets/money-outline.svg';

import { ReactComponent as LayersIcon } from 'assets/layers-icon.svg';
import { ReactComponent as LockIcon } from 'assets/lock-closed-outline.svg';
import { ReactComponent as VideoIcon } from 'assets/video-outline.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/whatsapp-icon-lg.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as SharedMenusIcon } from 'assets/shared-menus-icon.svg';
import { ReactComponent as PaymentLinkIcon } from 'assets/payment-link.svg';
import { ReactComponent as HelpIcon } from 'assets/help-icon-outline.svg';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import { useAppState } from 'context/global.context';
import { sideBarWidth, topNavBarHeight } from '@constants';
import clsx from 'clsx';
import { useGetMyInvites } from 'apiClient/api';

export const MenuButton = ({
  to,
  label,
  children,
  href,
  className,
}: {
  to: string;
  href?: string;
  children: any;
  label: string;
  className?: string;
}) => {
  const navigate = useNavigate();

  const handleClick = (to: string) =>
    href ? window.open(href, '_blank') : navigate(to);

  const { pathname } = useLocation();

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip {...props}>{label}</Tooltip>
  );

  return (
    <OverlayTrigger placement="right" overlay={renderTooltip}>
      <div
        onClick={() => handleClick(to)}
        className={clsx(
          'px-3 py-3 pointer rounded-3 align-self-center',
          pathname === to ? 'custom-shadow bg-white border-0' : '',
          className
        )}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

export const HasRole = ({ children, rol }: { children: any; rol: string }) => {
  const { user } = useAppState();
  if (rol && !user.roles?.includes(rol)) return null;
  return children;
};

export const Sidebar = () => {
  const { t } = useTranslation();
  const { user } = useAppState();

  const { data: invites } = useGetMyInvites({ enabled: !!user._id });

  const manageduserBtn = () => {
    return (
      <MenuButton
        to="/managed-menus"
        label={t('Menús compartidos')}
        className="position-relative "
      >
        <SharedMenusIcon style={{ height: 32, width: 32 }} />
        {invites?.length ? (
          <Badge className="position-absolute  top-0 ">{invites?.length}</Badge>
        ) : null}
      </MenuButton>
    );
  };

  const buttonsByUser = () => {
    if (user.isManagedUser) {
      return (
        <>
          {manageduserBtn()}
          <MenuButton to="/referals" label={t('Referidos')}>
            <CashIcon style={{ height: 32, width: 32 }} />
          </MenuButton>
        </>
      );
    }
    return (
      <>
        <MenuButton to="/" label={t('Mis menús')}>
          <GridIcon style={{ height: 32, width: 32 }} />
        </MenuButton>

        {user?.managedMenus?.length || invites?.length
          ? manageduserBtn()
          : null}

        {/* <MenuButton to="/payment-links" label={t('Enlaces pago')}>
          <PaymentLinkIcon style={{ height: 32, width: 32 }} />
        </MenuButton> */}

        <MenuButton to="/covers" label={t('Portadas')}>
          <LayersIcon style={{ height: 32, width: 32 }} />
        </MenuButton>

        <MenuButton to="/reservations" label={t('Reservaciones')}>
          <CalendarIcon style={{ height: 32, width: 32 }} />
        </MenuButton>

        <MenuButton to="/referals" label={t('Referidos')}>
          <CashIcon style={{ height: 32, width: 32 }} />
        </MenuButton>

        <MenuButton to="/clients" label={t('Clientes')}>
          <PeopleIcon style={{ height: 32, width: 32 }} />
        </MenuButton>
      </>
    );
  };

  return (
    <div
      className="position-fixed bg-light justify-content-between flex-column align-items-center py-3 gap-3 text-center px-3 d-none d-lg-flex"
      style={{ width: sideBarWidth, height: `calc(100% - ${topNavBarHeight})` }}
    >
      <div className="d-flex flex-column gap-3">
        {buttonsByUser()}

        <MenuButton to="/tutorial" label={t('Tutoriales')}>
          <VideoIcon style={{ height: 32, width: 32 }} />
        </MenuButton>
      </div>

      <div>
        <MenuButton
          to="/whatsapp"
          href="https://api.whatsapp.com/send/?phone=525521949216&text=Hola%2C+Necesito+mas+informaci%C3%B3n%21&type=phone_number&app_absent=0"
          label={t('Help')}
        >
          <HelpIcon style={{ height: 32, width: 32 }} />
        </MenuButton>
        <HasRole rol={'admin'}>
          <MenuButton to="/admin" label={t('admin')}>
            <LockIcon style={{ height: 32, width: 32 }} />
          </MenuButton>
        </HasRole>
      </div>
    </div>
  );
};
