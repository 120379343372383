import { useTranslation } from 'react-i18next';
import { PricesByTier } from '../profile/subscriptions/components/PricesByTier';
import { useStripePrices } from 'hooks/useStripePrices';
import { CurrencyConvertor } from '@meniu/ui';
import { Period } from '../profile/subscriptions/components/Period';
import { ReactComponent as StarsIcon } from 'assets/stars.svg';
import Loading from 'shared/loading.component';
import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { useAppDispatch, UserActions } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { useCreateStripePayment, useProfile } from 'apiClient/api';
import { useLocation, useNavigate } from 'react-router-dom';

export const TrialExpired = () => {
  const { prices, isLoading, setPeriod, period, id } = useStripePrices();

  const location = useLocation();

  const { t } = useTranslation();

  const { data: me = {}, isLoading: isLoadingProfile } = useProfile(undefined, {
    refetchInterval: location.pathname === '/trial-expired' ? 3000 : 10000,
  });

  const { mutate: mutateCreatePayment } = useCreateStripePayment();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/');
  };

  const handlePay = (price: string) => {
    if (me.email?.includes('@')) {
      mutateCreatePayment(
        {
          email: me.email || '',
          price,
          stripeAccount: me.stripeId,
        },
        {
          onSuccess: ({ url }) => {
            window.location.href = url;
          },
        }
      );
    } else {
      dispatch(UserActions.setModal(modalOptions.register));
    }
  };

  if (isLoading || isLoadingProfile) return <Loading />;

  return (
    <div className="container d-flex justify-content-center align-items-center h-100 pt-5">
      <div
        className="card border-0 p-4 flex gap-3 custom-shadow"
        style={{ maxWidth: '720px' }}
      >
        <div className="text-center flex gap-2">
          <h4 className="fw-semibold mb-2">
            {t('Tu prueba de Meniu ha finalizado')}
          </h4>
          <p className="fw-semibold small">
            {t('Compra el Plan Business para seguir usándolo')}
          </p>
        </div>
        <PricesByTier selectedPeriod={period} setPeriod={setPeriod} />

        {prices
          ?.filter(
            (d) =>
              d.recurring?.interval_count === period.period &&
              d.recurring?.interval === period.interval
          )
          .map((d) => (
            <div
              key={d.id}
              className="d-flex flex-column justify-content-center align-items-center gap-1 bg-light p-3 rounded-3 border"
            >
              <div className="d-flex flex-column gap-2">
                <div
                  className="d-flex gap-2 align-items-center justify-content-center fw-semibold"
                  style={{ textDecorationLine: 'line-through' }}
                >
                  <CurrencyConvertor
                    valueInMxn={
                      ((d?.unit_amount || 0) + period.discountAmount * 100) /
                      100
                    }
                  />
                </div>
              </div>

              <div className="d-flex flex-column">
                <h1 className="fw-semibold lh-1 mb-0">
                  <CurrencyConvertor valueInMxn={(d?.unit_amount || 0) / 100} />
                  <span className="fs-4">
                    /
                    <Period
                      interval={d.recurring?.interval}
                      interval_count={d.recurring?.interval_count}
                    />
                  </span>
                </h1>
              </div>
              <div>
                {period.discount && (
                  <div className="d-flex align-items-center gap-2 mt-2">
                    <p className="text-danger fw-semibold d-flex align-items-center gap-2">
                      <div className="fw-semibold lead rounded-1 badge-danger px-2 py-1 small rounded-2">
                        -{period.discount}
                      </div>
                      {t('Ahorra')}{' '}
                      <CurrencyConvertor valueInMxn={period.discountAmount} />{' '}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}

        <Button
          onClick={() => handlePay(id)}
          variant="dark"
          className="fw-semibold w-100 py-3 rounded-2 btn-animation "
        >
          {t('Comprar ahora')}
        </Button>

        {/* <Button
          hidden={me.trialExpiredNoAccess}
          onClick={goToDashboard}
          variant="outline-dark"
          className="fw-semibold w-100 py-3 rounded-2"
        >
          {t('Volver')}
        </Button> */}
        <div>
          <p className="fw-semibold mt-5 mb-2">{t('Precios por país')}</p>
          <Table hover className="small">
            <thead>
              <tr>
                <th className="ps-0">{t('País')}</th>
                <th>{t('Mensual')}</th>
                <th>{t('6 meses')}</th>
                <th className="pe-0">{t('1 año')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ps-0">{t('México')}</td>
                <td>$99 MXN</td>
                <td>$499 MXN</td>
                <td className="pe-0">$699 MXN</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Estados Unidos y Latinoamérica')}</td>
                <td>$4.80 USD</td>
                <td>$24.60 USD</td>
                <td className="pe-0">$34.40 USD</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Chile')}</td>
                <td>$4,50 CLP</td>
                <td>$22,90 CLP</td>
                <td className="pe-0">$32,00 CLP</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Europa')}</td>
                <td>4.50 € EUR</td>
                <td>23.00 € EUR</td>
                <td className="pe-0">32.00 € EUR</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Argentina')}</td>
                <td>$5,191.13 ARS</td>
                <td>$26,165.41 ARS</td>
                <td className="pe-0">$36,652.55 ARS</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Colombia')}</td>
                <td>$20,072.05 COP</td>
                <td>$101,171.25 COP</td>
                <td className="pe-0">$141,720.85 COP</td>
              </tr>
            </tbody>
          </Table>

          <div className="my-4 border-top border-light"></div>

          <div className="d-flex flex-sm-row flex-column gap-3">
            <div className="border p-3 rounded-3 w-100">
              <StarsIcon />
              <p className="fw-semibold my-3">
                {t('Excelente sistema, lo uso para mi cafetería')}
              </p>
              <p className="small mb-3">
                {t(
                  'Me permite llevar un control de mis ventas además de poder crear un menú digital que permite a mis clientes conocer mis productos de una manera ágil y moderna'
                )}
                .
              </p>
              <div>
                <p className="small fw-semibold">Enrique Pesantes</p>
                <p className="text-muted small">{t('Reseña de Google')}</p>
              </div>
            </div>
            <div className="border p-3 rounded-3 w-100">
              <StarsIcon />
              <p className="fw-semibold my-3">
                {t('Una app muy sencilla y fácil de manejar')}
              </p>
              <p className="small mb-3">
                {t(
                  'Cualquier problema te lo resuelven enseguida y en línea, un 10 para el equipo y la app. Muy recomendable'
                )}
                .
              </p>
              <div>
                <p className="small fw-semibold">Marin Ionut Duminica</p>
                <p className="text-muted small">{t('Reseña de Google')}</p>
              </div>
            </div>
          </div>
          <a
            href="https://g.co/kgs/Te5ca4w"
            target="_blank"
            className="small w-100 text-center fw-semibold d-block mt-3 text-dark"
          >
            {t('Ver más reseñas en Google')}
          </a>

          <div className="d-flex justify-content-center mt-4 ">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScbZo_9ZgcrN_uzi2KbdtyN1xd_vpcb05Ttyi8hF3uiUIKZ-g/viewform?usp=dialog"
              target="_blank"
              className="btn btn-sm bg-light fw-semibold border shadow-sm"
            >
              {t('Contestar encuesta')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialExpired;
