import Cookies from 'js-cookie';

export const fetcher = async ({
  url,
  body,
  method = 'GET',
  headers = {},
}: {
  url: string;
  body?: any;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  headers?: any;
}) => {
  const auth = localStorage.getItem('auth');
  const jwt: any = {};
  if (auth) {
    try {
      jwt.Authorization = `Bearer ${JSON.parse(auth)?.auth?.access_token}`;
    } catch (err) {
      console.error(err);
    }
  }

  const response = await fetch(`${process.env.NX_BACKEND_URL_}${url}`, {
    body: body instanceof FormData ? body : JSON.stringify(body),
    method,
    headers:
      body instanceof FormData
        ? { ...jwt }
        : {
            'Content-Type': 'application/json',
            ...jwt,
            ...headers,
            'x-country': Cookies.get('country'),
          },
  });
  if (!response.ok) {
    const er = await response.json();
    const { message, ...rest } = er;

    if (message === 'Unauthorized') {
      localStorage.removeItem('auth');
      window.location.href = '/login';
    }
    const msg = Array.isArray(message) ? message.join(',') : message;
    throw new Error(msg);
  }
  const data = await response.json();
  return data;
};
