// Styles.
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../i18n';
import * as Sentry from '@sentry/react';

// Libraries.
import { Slide, ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';

// Components.
import Routes from './Router';

// Context.
import {
  AppProvider,
  UserActions,
  useAppDispatch,
} from './context/global.context';
import Cookies from 'js-cookie';
import { v4 } from 'uuid';

export const queryClient = new QueryClient();

const LoginListener = ({ children }: any) => {
  const params = useSearchParams();

  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(search);
  const jwt = searchParams.get('jwt');
  useEffect(() => {
    if (jwt) {
      dispatch(
        UserActions.setToken(
          { access_token: jwt, success: true },
          undefined,
          () => {
            if (window.ReactNativeWebView) {
              location.reload();
            }
          }
        )
      );
    }
  }, [jwt]);
  return children;
};

export function App() {
  useEffect(() => {
    let sessionId = Cookies.get('sessionId');
    if (!sessionId) {
      sessionId = v4();
      Cookies.set('sessionId', sessionId, { domain: '.meniuapp.com' });
    }
  }, []);
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <GoogleOAuthProvider clientId={process.env.NX_GOOGLE_CLIENT_ID || ''}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            position="bottom-right"
            transition={Slide}
            hideProgressBar={true}
            icon={false}
            limit={3}
            autoClose={2000}
          />
          <ModalContainer />
          <Router>
            <AppProvider>
              <LoginListener>
                <Routes />
              </LoginListener>
            </AppProvider>
          </Router>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
